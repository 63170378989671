export function isContactValid(contact, options={}) {
  if(!contact) {
    return false
  }
  const requiredFields = ['first_name', 'last_name', 'email', 'job_title', 'company_id',
    'job_level', 'preferred_am_first_name', 'preferred_am_last_name', 'preferred_am_email'
  ];
  let hasRequiredFields = true;
  requiredFields.forEach(x=> {
    if(!contact[x]) {
      hasRequiredFields = false;
    }
  });
  if(!hasRequiredFields) {
    return false
  }

  if(options['validateCompany']){
    const company = options['company'] ? options['company'] : contact.company
    if(!isCompanyValid(company)) {
      return false
    }
  }
  return true;
}

export function isCompanyValid(company, options={}) {
  if(!company) {
    return false
  }
  const requiredFields = ['name', 'org_party_id', 'savmid', 'sales_coverage','sales_sub_coverage'];
  let isValid = true;
  requiredFields.forEach(x=> {
    if(!company[x]) {
      console.log("Not Valid");
      isValid = false
      return false
    }
  });
  return isValid;
}