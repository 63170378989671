import React from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form } from "formik";
import Checkbox from "@layout/forms/Checkbox";
import Cookies from "universal-cookie";
import {getCountrySelectOptions, getStateSelectOptions}  from "@utility/CountryCodes"
import {isJaguar, canSeeContactNotes, isSolutionsLead, isExecutiveRelationsLead, canEditContact, isAccountManager, canDeleteContact} from "@utility/PermissionUtility"
import Select from 'react-select-virtualized'
import { jobLevelOptions, cabMemberOptions, inviteeTypeOptions, relationshipWithCiscoOptions} from "@utility/OptionsUtils"
import DropzoneComponent from "@images/DropzoneComponent"
import UniversalImageCircle from "@images/UniversalImageCircle"
import {prefixOptions} from "./ContactUtils"
import { fetchAPI } from "@utility/NetworkUtils"
import { Countries, Provinces } from "country-and-province"
import { radioImages, checkboxImages } from "@utility/checkboxUtility"
import CompanyFormSlowLoad from "@companies/forms/CompanyFormSlowLoad"
import { isContactValid, isCompanyValid } from "@utility/validationUtility";
class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      contactImage: null,
      contactImagePreview: props.contact.contact_image,
      companyOpen: false,
      saving: false,
    };
    this.destroyContact = this.destroyContact.bind(this);
    this.saveAndNominate = this.saveAndNominate.bind(this);
    this.setImage = this.setImage.bind(this);
    this.checkUniqueValidation = this.checkUniqueValidation.bind(this);
    this.afterCompanyChange =  this.afterCompanyChange.bind(this);
    this.onCompanySave = this.onCompanySave.bind(this);
    this.saveAndValidateContact = this.saveAndValidateContact.bind(this);
  }
  componentDidMount() {
    if(this.props.contact && this.props.contact.company_id){
      this.fetchCompany(this.props.contact.company_id);
    }

  }

  isEditing() {
    const { contact } = this.props;
    return !!contact.id;
  }

  renderField(name,formikProps, required=false,customValidation = false, properties = {}) {
    const { user } = this.props;
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.contact[name] || formikProps.values.contact[name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { contact } = formikProps.values;
            contact[name] = e.target.value
            if(properties.stripValue) {
              contact[name] = (contact[name]||"").trim()
            }
            formikProps.setFieldValue('contact', contact);
            if(properties.onChange) {
              properties.onChange(formikProps)
            }
            this.setState({
              dirty: true,
            });
          }
        }
        style={{...properties['style']||{}}}
        disabled={!canEditContact(user)}
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`contact[${name}]`}
      />
    )
  }

  renderDateField(name,formikProps, required = false) {
    const { checkError } = this.state;
    const erroring = required && checkError && (!formikProps.values.contact[name] || formikProps.values.contact[name].length <= 0 || customValidation);

    return (
      <Field
        onChange={(e) => {
            const { contact } = formikProps.values;
            contact[name] = e.target.value
            formikProps.setFieldValue('contact', contact);
            this.setState({
              dirty: true,
            });
          }
        }
        component={properties['component']}
        className={`form-control ${erroring ? 'field-erroring': "" }`} type={properties['type']} name={`contact[${name}]`}
      />
    )
  }

  getCompanyValues() {
    const { companies } = this.props;
    return companies.map(x=>{
      return {
          value: x.id,
          label: x.name,
        }
      }
    )
  }

  padWithZeroes(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }
    return my_string;
  }

  checkUniqueValidation(formikProps) {
    const { contact } = formikProps.values;
    const contact_id = this.props.contact.id
    if(contact && contact.email) {
      const url = `/api/v1/contacts/validate_contact_new?email=${contact.email}${contact_id ? "&id="+contact_id : ""}`
      fetchAPI(
        url,
        (json) => {
          if(json.contact_exists) {
            this.setState({
              contactExists: true
            })
          }else {
            this.setState({
              contactExists: false
            })
          }
        }
      )
    }
  }


  renderContactExistsMessage() {
    const { user } =this.props;
    const { contactExists } = this.state;
    if(contactExists) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <br/>
            Looks like a contact with this email already exists. Please go to the {isAccountManager(user) ? "directory" : "contacts page"} to find out more.
          </div>
        </div>
      )
    }
  }

  editCompany(formikProps) {
    this.setState({
      companyOpen: true,
    })
  }

  createNewCompany(formikProps) {
    const { contact } = formikProps.values;
    contact['company_id'] = null
    formikProps.setFieldValue('contact', contact);
    this.setState({
      companyOpen: true,
    })
  }

  afterCompanyChange(formikProps, name, value) {
    this.setState({
      companyOpen: false,
      company: null
    })
    //Fetch Company Details
    console.log({
      value
    })
    if(value) {
      this.fetchCompany(value)
    }
  }

  fetchCompany(company_id) {
    fetchAPI(`/api/v1/companies/${company_id}/form`, (json) => {
      this.setState({
        company: json.company
      })
    })
  }

  renderCompanyForm(formikProps) {
    const { companyOpen } = this.state;
    const { user } = this.props;
    if(companyOpen) {
      return (
        <div className="col-xs-12 cnt-contact-form-inner-company-form">
          <CompanyFormSlowLoad
            companyId={formikProps.values.contact.company_id}
            newForm={!formikProps.values.contact.company_id}
            user={user}
            cancelCallback={()=>{
              this.setState({
                companyOpen: false
              })
            }}
            disableDelete={true}
            onRedirect={this.onCompanySave}
          />
        </div>
      )
    }
  }

  onCompanySave(json) {
    const { company } = json;
    this.fetchCompany(company.id)
    this.setState({
      companyOpen: false
    })
  }


  renderForm(formikProps) {
    const { error, saved, company, companyOpen} = this.state;
    const { user, isValidating } = this.props;
    const { values } = formikProps;
    const { contact } = values;
    const states = this.getStates(formikProps.values.contact.address_country)
    const home_states =  this.getStates(formikProps.values.contact.home_address_country)

    console.log({
      company,
      i: isCompanyValid(company),
      c: isValidating,
      d: companyOpen,
      b: isValidating && company && !isCompanyValid(company) && !companyOpen
    })
    return (
      <Form className="cnt-default-layout-contacts-form" >
        {
          isValidating ? (
            <div className="row">
              <div className="col-xs-12 col-lg-6">
                <br/>
                Mandatory fields are marked with an *
                <br/>
                Contacts can only be saved as validated once all mandatory fields are filled.
              </div>
              <div className="col-lg-6 hidden-xs hidden-sm hidden-md cnt-contact-validating-header-buttons">
                {this.renderButtons(formikProps)}
              </div>
                            
            </div>
          ):""
        }
        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-form-subheader">
              Contact Information
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 cnt-default-layout-event-form-error text-right">
            {error}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <label className="required">Mandatory Fields are marked with a </label>
          </div>


          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                First Name
              </label>
              {this.renderField('first_name', formikProps, true, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Middle
              </label>
              {this.renderField('middle_name', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Last Name
              </label>
              {this.renderField('last_name', formikProps, true,false)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-2">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Name Prefix
              </label>
              {this.renderSelectField('name_prefix', formikProps, prefixOptions())}
            </div>
          </div>
          <div className="col-xs-12 col-md-2">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Name Suffix
              </label>
              {this.renderField('name_suffix', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Official Job Title
              </label>
              {this.renderField('job_title', formikProps, true)}
            </div>
          </div>

          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Contact Job Level
              </label>
              {this.renderSelectField('job_level', formikProps, jobLevelOptions(), false, {required: true})}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Email
              </label>
              {this.renderField('email', formikProps, true, false, {
                onChange: this.checkUniqueValidation,
                stripValue: true,
              })}
              {isJaguar(user) || isSolutionsLead(user) || isExecutiveRelationsLead(user) ? (
                <Checkbox
                  label="Email Opt In"
                  value={!formikProps.values.contact.opt_out}
                  disabled={!canEditContact(user)}
                  images={checkboxImages()}
                  onClick={(e) => {
                      const { contact } = formikProps.values;
                      contact['opt_out'] = !formikProps.values.contact.opt_out
                      formikProps.setFieldValue('contact', contact);
                      this.setState({
                        dirty: true,
                      });
                    }
                  }
                />
              ):""}

            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Business Phone
              </label>
              {this.renderField('business_phone', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Mobile Phone
              </label>
              {this.renderField('mobile_phone', formikProps, false)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <br/>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Company
              </label>
              {this.renderSelectField("company_id", formikProps, this.getCompanyValues(), false, {
                required: true,
                afterChange: this.afterCompanyChange,
                isClearable: true
              })}
            </div>
            <div className="cnt-form-field-under-message">
              {formikProps.values.contact.company_id ? (
                <button className="cnt-submit-button" style={{marginLeft: 0, marginTop: "10px"}} type="button" onClick={()=>this.editCompany(formikProps)}>
                  Edit Company
                </button>
              ) : (
                <>
                  Don't see the company you are looking for?
                  <br/>
                  <button className="cnt-submit-button" style={{marginLeft: 0, marginTop: "10px"}} type="button" onClick={()=>this.createNewCompany(formikProps)} >
                    Create New Company
                  </button>
                </>
              )}
            </div>
            {isValidating && company && !isCompanyValid(company) && !companyOpen ? (
              <div style={{color: "red"}}><br/>This company has incomplete information</div>
            ):""}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-contact-form-am-provided-info-container">
              <div className="cnt-contact-form-am-provided-info-header">
                AM Provided Company Information
              </div>
              <div className="cnt-default-layout-event-form-input-container">
                <label className="">
                  Company Name
                </label>
                {this.renderField('am_provided_company_name', formikProps, false)}
              </div>

              <div className="cnt-default-layout-event-form-input-container">
                <label className="">
                  Area
                </label>
                {this.renderField('am_provided_area', formikProps, false)}
              </div>

              <div className="cnt-default-layout-event-form-input-container">
                <label className="">
                  Theater
                </label>
                {this.renderField('am_provided_theater', formikProps, false)}
              </div>

              <div className="cnt-default-layout-event-form-input-container">
                <label className="">
                  Tier / Segment
                </label>
                {this.renderField('am_provided_segment', formikProps, false)}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {this.renderCompanyForm(formikProps)}
        </div>
        <div className="row">
          {formikProps.values.contact.contact_type == "partner" ? (
            <div className="col-xs-12 col-md-4">
              <div className="cnt-default-layout-event-form-input-container">
                <label className="required">
                  BE ID
                </label>
                {this.renderField('beid', formikProps, true)}
              </div>
            </div>
          ):""}


          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                CAB Member
              </label>
              {this.renderSelectField('cab_member', formikProps, cabMemberOptions(), false, {required: false})}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Invitee Type
              </label>
              {this.renderSelectField('invitee_type', formikProps, inviteeTypeOptions(), false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Relationship with Cisco
              </label>
              {this.renderSelectField('relationship_with_cisco', formikProps, relationshipWithCiscoOptions(), false)}
            </div>
          </div>
        </div>

        {this.renderContactExistsMessage()}
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Assistant Name
              </label>
              {this.renderField('assistant_name', formikProps, false)}
              {isJaguar(user) || isSolutionsLead(user) || isExecutiveRelationsLead(user) ? (
              <Checkbox
                label="Assistant Opt In"
                disabled={!canEditContact(user)}
                images={checkboxImages()}
                value={!formikProps.values.contact.assistant_opt_out}
                onClick={(e) => {
                    const { contact } = formikProps.values;
                    contact['assistant_opt_out'] = !formikProps.values.contact.assistant_opt_out
                    formikProps.setFieldValue('contact', contact);
                    this.setState({
                      dirty: true,
                    });
                  }
                }
              />):""}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Assistant Email
              </label>
              {this.renderField('assistant_email', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Assistant Phone
              </label>
              {this.renderField('assistant_phone', formikProps, false)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Preferred AM First Name
              </label>
              {this.renderField('preferred_am_first_name', formikProps, true, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Preferred AM Last Name
              </label>
              {this.renderField('preferred_am_last_name', formikProps, true, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="required">
                Preferred AM Email
              </label>
              {this.renderField('preferred_am_email', formikProps, true, false )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
              Preferred Invite Sender First Name
              </label>
              {this.renderField('preferred_invite_sender_first_name', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Preferred Invite Sender Last Name
              </label>
              {this.renderField('preffered_invite_sender_last_name', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Preferred Invite Sender Email
              </label>
              {this.renderField('preferred_invite_sender_email', formikProps, false)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Executive Sponsor
              </label>
              {this.renderField('executive_sponsor', formikProps, false)}
            </div>
          </div>
          {isJaguar(user) || isSolutionsLead(user) || isExecutiveRelationsLead(user) ? (
            <div className="col-xs-12 col-md-4">
              <div style={{marginTop:"10px"}}className="cnt-default-layout-event-form-input-container">
                <br/>
                <Checkbox
                  label="VIP"
                  value={formikProps.values.contact.vip}
                  disabled={!canEditContact(user)}
                  images={checkboxImages()}
                  onClick={(e) => {
                      const { contact } = formikProps.values;
                      contact['vip'] = !formikProps.values.contact.vip
                      formikProps.setFieldValue('contact', contact);
                      this.setState({
                        dirty: true,
                      });
                    }
                  }
                />
              </div>
            </div>
          ):""}
        </div>
        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-form-subheader">
              Addresses
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 cnt-form-teritiary-header">
            Business Address
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Country
              </label>
              {this.renderSelectField("address_country", formikProps, getCountrySelectOptions())}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Address 1
              </label>
              {this.renderField('address_line_1', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Address 2
              </label>
              {this.renderField('address_line_2', formikProps, false)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                City
              </label>
              {this.renderField('address_city', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                State/Province
              </label>
              {states && states.length > 0 ? (
                this.renderSelectField('address_state', formikProps, states)
              ):(this.renderField('address_state', formikProps, false))}
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Postal Code
              </label>
              {this.renderField('postal_code', formikProps, false)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 cnt-form-teritiary-header">
            Home Address
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Country
              </label>
              {this.renderSelectField("home_address_country", formikProps, getCountrySelectOptions())}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Address 1
              </label>
              {this.renderField('home_address_line_1', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Address 2
              </label>
              {this.renderField('home_address_line_2', formikProps, false)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                City
              </label>
              {this.renderField('home_address_city', formikProps, false)}
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                State/Province
              </label>
              {home_states && home_states.length > 0 ? (
                this.renderSelectField('home_address_state', formikProps, home_states)
              ):(this.renderField('home_address_state', formikProps, false))}
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="cnt-default-layout-event-form-input-container">
              <label className="">
                Postal Code
              </label>
              {this.renderField('home_postal_code', formikProps, false)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 ">
            <div className="cnt-form-subheader">
            </div>
          </div>
        </div>
        <div className="row">
          {canSeeContactNotes(user) ? (
            <div className="col-xs-12 col-md-8">
              <label className="">
                Notes
              </label>
              {this.renderField('notes', formikProps, false,false, {component: 'textarea', style: {minHeight: '200px'}})}
            </div>
          ):""}
        </div>
        <div className="row">
          <div className="col-xs-12 cnt-default-layout-event-form-error text-right">
            {error}
          </div>
          {saved ? (
            <div className="col-xs-12">
              Successfully Saved Contact
            </div>
          ):""}
          {this.renderButtons(formikProps)}
        </div>
      </Form>
    )
  }


  isDirty() {
    const { dirty } = this.state;
    return dirty
  }

  setImage(name, file, preview, tagList) {
    this.setState({
      contactImage: file,
      contactImagePreview:preview,
      dirty: true,
    });
  }

  getAccountManagerOptions() {
    const { users } = this.props;
    return users.map(x=> {return {
      value: x.id,
      label: `${x.first_name} ${x.last_name}`
    }})
  }

  getStates(country_name) {
    try {
      const country = Countries.byName(country_name||"");
      if(country && country.provinces && country.provinces.data) {
        return country.provinces.data.map(x=>{
          return {
            label: x.name,
            value: x.name
          }
        })
      }
      return null;
    }catch(e){
      return null;
    }
  }

  saveAndValidateContact(formikProps) {
    const { nomination } = this.props
    formikProps.setFieldValue('validate_nomination', nomination.id)
    formikProps.handleSubmit()
  }

  renderButtons(formikProps) {
    const { dirty } = this.state;
    const { user, disableDelete, cancelCallback, forceDirty, isValidating } = this.props;
    const { company, saving } = this.state;
    const isSubmitButtonDisabled = !(formikProps.dirty || dirty || forceDirty || saving)
    if(canEditContact(user)) {
      if(isValidating) {
        const isFormValid = isContactValid(formikProps.values.contact, {
          validateCompany: true,
          company: company
        }) //TODO
        return (
          <div className="col-xs-12 text-right" style={{paddingTop: "20px"}}>
            <button onClick={cancelCallback ? cancelCallback : ()=>{window.location="/contacts"}} type="button" className="cnt-cancel-button "> Cancel </button>
            <button type="button"
              onClick={() => {
                formikProps.setFieldValue('validate_nomination', null)
                formikProps.handleSubmit()
              }}
              style={{backgroundColor: "#F4A700"}}
              className={"cnt-submit-button"}
            > {saving && !formikProps.values.validate_nomination ? "Saving..." : "Save Unverified"} </button>

            <button type="button"
              onClick={(e)=>
               this.saveAndValidateContact(formikProps)
              }
              disabled={!isFormValid}
              className={isFormValid ? "cnt-submit-button" : "cnt-disabled-button "}
            > {saving && formikProps.values.validate_nomination ? "Saving..." : "Save as Validated Contact"}  </button>
            
          </div>
        ) 
      }
      return (
        <div className="col-xs-12" style={{paddingTop: "20px"}}>
          <button type="button"
            onClick={() => formikProps.handleSubmit()}
            disabled={isSubmitButtonDisabled}
            className={!isSubmitButtonDisabled ? "cnt-submit-button" : "cnt-disabled-button "}
          > {saving ? "Saving..." : "Save"} </button>
          <button onClick={cancelCallback ? cancelCallback : ()=>{window.location="/contacts"}} type="button" className="cnt-cancel-button "> Cancel </button>
          {this.isEditing() && canDeleteContact(user) && !disableDelete ? (<button onClick={this.destroyContact} type="button" className="cnt-delete-button"> Delete </button>) : ""}
        </div>
      )
    }
  }

  saveAndNominate(formikProps) {
    formikProps.values['nominate'] = true;
    formikProps.handleSubmit()
  }



  destroyContact() {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    if(!confirm("Are you sure you want to delete this contact?")) {
      return;
    }
    fetch(this.formURL(), {
      method: "DELETE",
      redirect: "manual",
      body: null,
      headers: {
        "X-CSRF-Token": token,
        "Content-Type": 'application/json'
      }
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.error == null) {
          if(json.redirect_url) {
            window.location = json.redirect_url
          }else {
            console.log("Successful no redirect")
          }
        }
        else {
          this.setState({
            error: json.error
          })
        }
      });
  }
  renderSelectField(name, formikProps, options, customValidation = false, renderOptions = {}) {
    const { checkError } = this.state;
    const erroring = checkError && renderOptions['required'] && ((formikProps.values.contact[name] == null || formikProps.values.contact[name].length <= 0) || customValidation);
    let value = null;
    try {
       value = options.filter(option => option.value === formikProps.values['contact'][name])[0]
    }catch (e) {

    }
      return (
        <Select
          className={erroring ? "cnt-form-select-erroring" : ""}
          options={options}
          grouped={renderOptions["grouped"]}
          classNamePrefix='cnt-form-select'
          isClearable={renderOptions.isClearable || false}
          value={options ? value : {value: "", label: ""}}
          onChange={(option) => {
            const { contact } = formikProps.values;
            contact[name] = (option||{}).value
            this.setState({
              dirty: true
            })
            formikProps.setFieldValue('contact', contact)
            if(renderOptions.afterChange){
              renderOptions.afterChange(formikProps, name, contact[name])
            }
          }}
          isDisabled={renderOptions['disabled']}
          filterOption={this.customFilter}
        />
      )
    }


  formMethod() {
    return this.isEditing() ? "PATCH" : "POST"
  }

  formURL() {
    const { contact } = this.props;
    return this.isEditing() ? `/contacts/${contact.id}` : `/contacts`;
  }

  generateDefaultAccountManager() {
    const { user } = this.props
    if(isAccountManager(user)) {
      return user.id
    }
    return null
  }

  saveFormToCookies(formikProps) {
    const values = formikProps.values;
    const temp_id = Math.floor(Math.random()*1000)+1
    values.temp_id = temp_id
    localStorage.setItem("ContactFormTemp", JSON.stringify(values));
    return temp_id
  }

  createNewUser(formikProps) {
    const id = this.saveFormToCookies(formikProps);
    window.location=`/admin/users/new?temporary_contact_id=${id}`
  }

  getCookieInitialValues() {
    const { temporary_contact_id, company_id} = this.props;
    const cookie = localStorage.getItem("ContactFormTemp");
    if(temporary_contact_id && cookie){
      const values = JSON.parse(cookie) || {};
      if(values && values.temp_id && values.temp_id == temporary_contact_id) {
        if(company_id) {
          values.contact.company_id = parseInt(company_id)
        }
        return values;
      }
    }
  }

  render() {
    const { user, from_nomination, contact, return_nomination, return_nomination_event } = this.props;
    return (
      <>
      <Formik
        initialValues={this.getCookieInitialValues() || {
          contact: {
            first_name: contact.first_name || "",
            middle_name: contact.middle_name || "",
            last_name: contact.last_name || "",
            name_pronunciation: contact.name_pronunciation || "", //NEEDS Migration
            company_id: contact.company_id || "",
            job_title: contact.job_title || contact.nom_official_job_title || "",
            job_level: contact.job_level || "",//NEEDS Migration
            email: contact.email || "",
            mobile_phone: contact.mobile_phone || "",
            business_phone: contact.business_phone || "",
            opt_out: !!contact.opt_out,

            address_country: contact.address_country || "",
            address_line_1: contact.address_line_1 || "",
            address_line_2: contact.address_line_2 || "",
            address_city: contact.address_city || "",
            postal_code: contact.postal_code || "",

            home_address_country: contact.home_address_country || "",
            home_address_line_1: contact.home_address_line_1 || "",
            home_address_line_2: contact.home_address_line_2 || "",
            home_address_city: contact.home_address_city || "",
            home_postal_code: contact.home_postal_code || "",
            home_address_state: contact.home_address_state || "",

            assistant_name: contact.assistant_name || "",
            assistant_email: contact.assistant_email || "",
            assistant_phone: contact.assistant_phone || "",
            assistant_opt_out: !!contact.assistant_opt_out,
            cab_member: contact.cab_member || "",
            contact_type: contact.contact_type || "",
            beid: contact.beid || "",
            name_prefix: contact.name_prefix || "",

            name_suffix: contact.name_suffix || "",

            address_state: contact.address_state || "",
            preferred_first_name_on_badge: contact.preferred_first_name_on_badge || "",

            preferred_given_name: contact.preferred_given_name || "",
            attendee_type: contact.attendee_type || "",
            gender: contact.gender || "",

            area: contact.area || "",
            emergency_contact_email: contact.emergency_contact_email || "",
            emergency_contact_relation: contact.emergency_contact_relation || "",
            executive_assistant: contact.executive_assistant || "",
            notes: contact.notes || "",
            source: contact.source || "",
            vip: contact.vip,
            am_provided_company_name: contact.am_provided_company_name || "",
            am_provided_segment: contact.am_provided_segment || "",
            am_provided_area: contact.am_provided_area || "",
            am_provided_theater: contact.am_provided_theater || "",
            preferred_am_email: contact.preferred_am_email || "",
            preferred_am_first_name: contact.preferred_am_first_name || "",
            preferred_am_last_name: contact.preferred_am_last_name || "",
            executive_sponsor: contact.executive_sponsor || "",

            preferred_invite_sender_first_name: contact.preferred_invite_sender_first_name || "",
            preferred_invite_sender_last_name: contact.preferred_invite_sender_last_name || "",
            preferred_invite_sender_email: contact.preferred_invite_sender_email || "",
          }
        }}
        onSubmit={(values, actions) => {
          const cookies = new Cookies();
          const token = cookies.get("X-CSRF-Token");
          const { contactImage } = this.state;
          var form_data = new FormData();
          if(contactImage) {
            form_data.append('contact[contact_image]', contactImage);
          }

          this.setState({
            checkError: false,
            saved: false,
            saving: true
          })

          if(values.validate_nomination){
            form_data.append('validate_nomination', values.validate_nomination);
          }

          for ( var key in values['contact'] ) {
            form_data.append(`contact[${key}]`, values['contact'][key]);
          }
          fetch(this.formURL(), {
            method: this.formMethod(),
            redirect: "manual",
            body: form_data,
            headers: {
              "X-CSRF-Token": token
            }
          })
            .then(response => {
              return response.json();
            })
            .then(json => {
              this.setState({
                saving: false
              })
              if (json.error == null) {
                if(this.props.onRedirect) {
                  this.props.onRedirect(json);
                  return;
                }
                if(return_nomination == 0) {
                  window.location =`/nominations/new?contact_id=${json.contact_id}&event_id=${return_nomination_event}`
                  return;
                }else if(return_nomination != null ) {
                  window.location =`/nominations/${return_nomination}?contact_id=${json.contact_id}&event_id=${return_nomination_event}`
                  return;
                }else if (from_nomination != null) {
                  window.location =`/nominations/new?contact_id=${json.contact_id}&event_id=${return_nomination_event}`
                  return;
                }
                else if(json.redirect_url && contact.id == null) {
                  window.location = json.redirect_url
                }else {
                  this.setState({
                    saved: true,
                    saving: false,
                    error: null,
                  })
                }
              }else {
                this.setState({
                  error: json.error,
                  checkError: true,
                  saving: false,
                })
              }
            });
        }}
        render={(formikProps) => (
          <>
            {this.renderForm(formikProps)}
          </>
        )}
      />
      </>
    );
  }
}

export default ContactForm;
