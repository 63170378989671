export function getCountrySelectOptions() {
  return getCountryInfo().map(country => {return {label: country.name, value: country.name}});
}

export function getCountryInfo() {
  return [
    {
      "code": "US",
      "name": "UNITED STATES",
      "futurenames": ""
    },
    {
      "code": "AF",
      "name": "AFGHANISTAN",
      "futurenames": ""
    },
    {
      "code": "AX",
      "name": "ALAND ISLANDS",
      "futurenames": ""
    },
    {
      "code": "AL",
      "name": "ALBANIA",
      "futurenames": ""
    },
    {
      "code": "DZ",
      "name": "ALGERIA",
      "futurenames": ""
    },
    {
      "code": "AS",
      "name": "AMERICAN SAMOA",
      "futurenames": ""
    },
    {
      "code": "AD",
      "name": "ANDORRA",
      "futurenames": ""
    },
    {
      "code": "AO",
      "name": "ANGOLA",
      "futurenames": ""
    },
    {
      "code": "AI",
      "name": "ANGUILLA",
      "futurenames": ""
    },
    {
      "code": "AQ",
      "name": "ANTARCTICA",
      "futurenames": ""
    },
    {
      "code": "AG",
      "name": "ANTIGUA AND BARBUDA",
      "futurenames": ""
    },
    {
      "code": "AR",
      "name": "ARGENTINA",
      "futurenames": ""
    },
    {
      "code": "AM",
      "name": "ARMENIA",
      "futurenames": ""
    },
    {
      "code": "AW",
      "name": "ARUBA",
      "futurenames": ""
    },
    {
      "code": "AU",
      "name": "AUSTRALIA",
      "futurenames": ""
    },
    {
      "code": "AT",
      "name": "AUSTRIA",
      "futurenames": ""
    },
    {
      "code": "AZ",
      "name": "AZERBAIJAN",
      "futurenames": ""
    },
    {
      "code": "BS",
      "name": "BAHAMAS",
      "futurenames": ""
    },
    {
      "code": "BH",
      "name": "BAHRAIN",
      "futurenames": ""
    },
    {
      "code": "BD",
      "name": "BANGLADESH",
      "futurenames": ""
    },
    {
      "code": "BB",
      "name": "BARBADOS",
      "futurenames": ""
    },
    {
      "code": "BE",
      "name": "BELGIUM",
      "futurenames": ""
    },
    {
      "code": "BZ",
      "name": "BELIZE",
      "futurenames": ""
    },
    {
      "code": "BJ",
      "name": "BENIN",
      "futurenames": ""
    },
    {
      "code": "BM",
      "name": "BERMUDA",
      "futurenames": ""
    },
    {
      "code": "BT",
      "name": "BHUTAN",
      "futurenames": ""
    },
    {
      "code": "BO",
      "name": "BOLIVIA",
      "futurenames": ""
    },
    {
      "code": "BQ",
      "name": "BONAIRE, SINT EUSTATIUS AND SABA",
      "futurenames": ""
    },
    {
      "code": "BA",
      "name": "BOSNIA AND HERZEGOVINA",
      "futurenames": ""
    },
    {
      "code": "BW",
      "name": "BOTSWANA",
      "futurenames": ""
    },
    {
      "code": "BV",
      "name": "BOUVET ISLAND",
      "futurenames": ""
    },
    {
      "code": "BR",
      "name": "BRAZIL",
      "futurenames": ""
    },
    {
      "code": "IO",
      "name": "BRITISH INDIAN OCEAN TERRITORY",
      "futurenames": ""
    },
    {
      "code": "BN",
      "name": "BRUNEI DARUSSALAM",
      "futurenames": ""
    },
    {
      "code": "BG",
      "name": "BULGARIA",
      "futurenames": ""
    },
    {
      "code": "BF",
      "name": "BURKINA FASO",
      "futurenames": ""
    },
    {
      "code": "BI",
      "name": "BURUNDI",
      "futurenames": ""
    },
    {
      "code": "KH",
      "name": "CAMBODIA",
      "futurenames": ""
    },
    {
      "code": "CM",
      "name": "CAMEROON",
      "futurenames": ""
    },
    {
      "code": "CA",
      "name": "CANADA",
      "futurenames": ""
    },
    {
      "code": "CV",
      "name": "CAPE VERDE",
      "futurenames": ""
    },
    {
      "code": "KY",
      "name": "CAYMAN ISLANDS",
      "futurenames": ""
    },
    {
      "code": "CF",
      "name": "CENTRAL AFRICAN REPUBLIC",
      "futurenames": ""
    },
    {
      "code": "TD",
      "name": "CHAD",
      "futurenames": ""
    },
    {
      "code": "CL",
      "name": "CHILE",
      "futurenames": ""
    },
    {
      "code": "CN",
      "name": "CHINA",
      "futurenames": ""
    },
    {
      "code": "CX",
      "name": "CHRISTMAS ISLAND",
      "futurenames": ""
    },
    {
      "code": "CC",
      "name": "COCOS (KEELING) ISLANDS",
      "futurenames": ""
    },
    {
      "code": "CO",
      "name": "COLOMBIA",
      "futurenames": ""
    },
    {
      "code": "KM",
      "name": "COMOROS",
      "futurenames": ""
    },
    {
      "code": "CG",
      "name": "CONGO",
      "futurenames": ""
    },
    {
      "code": "CD",
      "name": "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
      "futurenames": ""
    },
    {
      "code": "CK",
      "name": "COOK ISLANDS",
      "futurenames": ""
    },
    {
      "code": "CR",
      "name": "COSTA RICA",
      "futurenames": ""
    },
    {
      "code": "CI",
      "name": "CÔTE D'IVOIRE",
      "futurenames": ""
    },
    {
      "code": "HR",
      "name": "CROATIA",
      "futurenames": ""
    },
    {
      "code": "CU",
      "name": "CUBA",
      "futurenames": ""
    },
    {
      "code": "CW",
      "name": "CURACAO",
      "futurenames": ""
    },
    {
      "code": "CY",
      "name": "CYPRUS",
      "futurenames": ""
    },
    {
      "code": "CZ",
      "name": "CZECH REPUBLIC",
      "futurenames": ""
    },
    {
      "code": "DK",
      "name": "DENMARK",
      "futurenames": ""
    },
    {
      "code": "DJ",
      "name": "DJIBOUTI",
      "futurenames": ""
    },
    {
      "code": "DM",
      "name": "DOMINICA",
      "futurenames": ""
    },
    {
      "code": "DO",
      "name": "DOMINICAN REPUBLIC",
      "futurenames": ""
    },
    {
      "code": "EC",
      "name": "ECUADOR",
      "futurenames": ""
    },
    {
      "code": "EG",
      "name": "EGYPT",
      "futurenames": ""
    },
    {
      "code": "SV",
      "name": "EL SALVADOR",
      "futurenames": ""
    },
    {
      "code": "GQ",
      "name": "EQUATORIAL GUINEA",
      "futurenames": ""
    },
    {
      "code": "ER",
      "name": "ERITREA",
      "futurenames": ""
    },
    {
      "code": "EE",
      "name": "ESTONIA",
      "futurenames": ""
    },
    {
      "code": "ET",
      "name": "ETHIOPIA",
      "futurenames": ""
    },
    {
      "code": "FK",
      "name": "FALKLAND ISLANDS (MALVINAS)",
      "futurenames": ""
    },
    {
      "code": "FO",
      "name": "FAROE ISLANDS",
      "futurenames": ""
    },
    {
      "code": "FJ",
      "name": "FIJI",
      "futurenames": ""
    },
    {
      "code": "FI",
      "name": "FINLAND",
      "futurenames": ""
    },
    {
      "code": "FR",
      "name": "FRANCE",
      "futurenames": ""
    },
    {
      "code": "GF",
      "name": "FRENCH GUIANA",
      "futurenames": ""
    },
    {
      "code": "PF",
      "name": "FRENCH POLYNESIA",
      "futurenames": ""
    },
    {
      "code": "TF",
      "name": "FRENCH SOUTHERN TERRITORIES",
      "futurenames": ""
    },
    {
      "code": "GA",
      "name": "GABON",
      "futurenames": ""
    },
    {
      "code": "GM",
      "name": "GAMBIA",
      "futurenames": ""
    },
    {
      "code": "GE",
      "name": "GEORGIA",
      "futurenames": ""
    },
    {
      "code": "DE",
      "name": "GERMANY",
      "futurenames": ""
    },
    {
      "code": "GH",
      "name": "GHANA",
      "futurenames": ""
    },
    {
      "code": "GI",
      "name": "GIBRALTAR",
      "futurenames": ""
    },
    {
      "code": "GB",
      "name": "GREAT BRITAIN",
      "futurenames": ""
    },
    {
      "code": "GR",
      "name": "GREECE",
      "futurenames": ""
    },
    {
      "code": "GL",
      "name": "GREENLAND",
      "futurenames": ""
    },
    {
      "code": "GD",
      "name": "GRENADA",
      "futurenames": ""
    },
    {
      "code": "GP",
      "name": "GUADELOUPE",
      "futurenames": ""
    },
    {
      "code": "GU",
      "name": "GUAM",
      "futurenames": ""
    },
    {
      "code": "GT",
      "name": "GUATEMALA",
      "futurenames": ""
    },
    {
      "code": "GG",
      "name": "GUERNSEY",
      "futurenames": ""
    },
    {
      "code": "GN",
      "name": "GUINEA",
      "futurenames": ""
    },
    {
      "code": "GW",
      "name": "GUINEA-BISSAU",
      "futurenames": ""
    },
    {
      "code": "GY",
      "name": "GUYANA",
      "futurenames": ""
    },
    {
      "code": "HT",
      "name": "HAITI",
      "futurenames": ""
    },
    {
      "code": "HM",
      "name": "HEARD ISLAND AND MCDONALD ISLANDS",
      "futurenames": ""
    },
    {
      "code": "VA",
      "name": "HOLY SEE (VATICAN CITY STATE)",
      "futurenames": ""
    },
    {
      "code": "HN",
      "name": "HONDURAS",
      "futurenames": ""
    },
    {
      "code": "HK",
      "name": "HONG KONG SAR",
      "futurenames": ""
    },
    {
      "code": "HU",
      "name": "HUNGARY",
      "futurenames": ""
    },
    {
      "code": "IS",
      "name": "ICELAND",
      "futurenames": ""
    },
    {
      "code": "IN",
      "name": "INDIA",
      "futurenames": ""
    },
    {
      "code": "ID",
      "name": "INDONESIA",
      "futurenames": ""
    },
    {
      "code": "IR",
      "name": "IRAN, ISLAMIC REPUBLIC OF",
      "futurenames": ""
    },
    {
      "code": "IQ",
      "name": "IRAQ",
      "futurenames": ""
    },
    {
      "code": "IE",
      "name": "IRELAND",
      "futurenames": ""
    },
    {
      "code": "IM",
      "name": "ISLE OF MAN",
      "futurenames": ""
    },
    {
      "code": "IL",
      "name": "ISRAEL",
      "futurenames": ""
    },
    {
      "code": "IT",
      "name": "ITALY",
      "futurenames": ""
    },
    {
      "code": "JM",
      "name": "JAMAICA",
      "futurenames": ""
    },
    {
      "code": "JP",
      "name": "JAPAN",
      "futurenames": ""
    },
    {
      "code": "JE",
      "name": "JERSEY",
      "futurenames": ""
    },
    {
      "code": "JO",
      "name": "JORDAN",
      "futurenames": ""
    },
    {
      "code": "KZ",
      "name": "KAZAKHSTAN",
      "futurenames": ""
    },
    {
      "code": "KE",
      "name": "KENYA",
      "futurenames": ""
    },
    {
      "code": "KI",
      "name": "KIRIBATI",
      "futurenames": ""
    },
    {
      "code": "KP",
      "name": "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
      "futurenames": ""
    },
    {
      "code": "KR",
      "name": "KOREA, REPUBLIC OF",
      "futurenames": ""
    },
    {
      "code": "XK",
      "name": "KOSOVO",
      "futurenames": ""
    },
    {
      "code": "KW",
      "name": "KUWAIT",
      "futurenames": ""
    },
    {
      "code": "KG",
      "name": "KYRGYZSTAN",
      "futurenames": ""
    },
    {
      "code": "LA",
      "name": "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
      "futurenames": ""
    },
    {
      "code": "LV",
      "name": "LATVIA",
      "futurenames": ""
    },
    {
      "code": "LB",
      "name": "LEBANON",
      "futurenames": ""
    },
    {
      "code": "LS",
      "name": "LESOTHO",
      "futurenames": ""
    },
    {
      "code": "LR",
      "name": "LIBERIA",
      "futurenames": ""
    },
    {
      "code": "LY",
      "name": "LIBYAN ARAB JAMAHIRIYA",
      "futurenames": ""
    },
    {
      "code": "LI",
      "name": "LIECHTENSTEIN",
      "futurenames": ""
    },
    {
      "code": "LT",
      "name": "LITHUANIA",
      "futurenames": ""
    },
    {
      "code": "LU",
      "name": "LUXEMBOURG",
      "futurenames": ""
    },
    {
      "code": "MO",
      "name": "MACAO",
      "futurenames": "MACAO SAR"
    },
    {
      "code": "MK",
      "name": "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF",
      "futurenames": "NORTH MACEDONIA"
    },
    {
      "code": "MG",
      "name": "MADAGASCAR",
      "futurenames": ""
    },
    {
      "code": "MW",
      "name": "MALAWI",
      "futurenames": ""
    },
    {
      "code": "MY",
      "name": "MALAYSIA",
      "futurenames": ""
    },
    {
      "code": "MV",
      "name": "MALDIVES",
      "futurenames": ""
    },
    {
      "code": "ML",
      "name": "MALI",
      "futurenames": ""
    },
    {
      "code": "MT",
      "name": "MALTA",
      "futurenames": ""
    },
    {
      "code": "MH",
      "name": "MARSHALL ISLANDS",
      "futurenames": ""
    },
    {
      "code": "MQ",
      "name": "MARTINIQUE",
      "futurenames": ""
    },
    {
      "code": "MR",
      "name": "MAURITANIA",
      "futurenames": ""
    },
    {
      "code": "MU",
      "name": "MAURITIUS",
      "futurenames": ""
    },
    {
      "code": "YT",
      "name": "MAYOTTE",
      "futurenames": ""
    },
    {
      "code": "MX",
      "name": "MEXICO",
      "futurenames": ""
    },
    {
      "code": "FM",
      "name": "MICRONESIA, FEDERATED STATES OF",
      "futurenames": ""
    },
    {
      "code": "MD",
      "name": "MOLDOVA, REPUBLIC OF",
      "futurenames": ""
    },
    {
      "code": "MC",
      "name": "MONACO",
      "futurenames": ""
    },
    {
      "code": "MN",
      "name": "MONGOLIA",
      "futurenames": ""
    },
    {
      "code": "ME",
      "name": "MONTENEGRO",
      "futurenames": ""
    },
    {
      "code": "MS",
      "name": "MONTSERRAT",
      "futurenames": ""
    },
    {
      "code": "MA",
      "name": "MOROCCO",
      "futurenames": ""
    },
    {
      "code": "MZ",
      "name": "MOZAMBIQUE",
      "futurenames": ""
    },
    {
      "code": "MM",
      "name": "MYANMAR",
      "futurenames": ""
    },
    {
      "code": "NA",
      "name": "NAMIBIA",
      "futurenames": ""
    },
    {
      "code": "NR",
      "name": "NAURU",
      "futurenames": ""
    },
    {
      "code": "NP",
      "name": "NEPAL",
      "futurenames": ""
    },
    {
      "code": "NL",
      "name": "NETHERLANDS",
      "futurenames": ""
    },
    {
      "code": "AN",
      "name": "NETHERLANDS ANTILLES",
      "futurenames": ""
    },
    {
      "code": "NT",
      "name": "NEUTRAL ZONE",
      "futurenames": ""
    },
    {
      "code": "NC",
      "name": "NEW CALEDONIA",
      "futurenames": ""
    },
    {
      "code": "NZ",
      "name": "NEW ZEALAND",
      "futurenames": ""
    },
    {
      "code": "NI",
      "name": "NICARAGUA",
      "futurenames": ""
    },
    {
      "code": "NE",
      "name": "NIGER",
      "futurenames": ""
    },
    {
      "code": "NG",
      "name": "NIGERIA",
      "futurenames": ""
    },
    {
      "code": "NU",
      "name": "NIUE",
      "futurenames": ""
    },
    {
      "code": "NF",
      "name": "NORFOLK ISLAND",
      "futurenames": ""
    },
    {
      "code": "MP",
      "name": "NORTHERN MARIANA ISLANDS",
      "futurenames": ""
    },
    {
      "code": "NO",
      "name": "NORWAY",
      "futurenames": ""
    },
    {
      "code": "OM",
      "name": "OMAN",
      "futurenames": ""
    },
    {
      "code": "PK",
      "name": "PAKISTAN",
      "futurenames": ""
    },
    {
      "code": "PW",
      "name": "PALAU",
      "futurenames": ""
    },
    {
      "code": "PS",
      "name": "PALESTINE, STATE OF",
      "futurenames": ""
    },
    {
      "code": "PA",
      "name": "PANAMA",
      "futurenames": ""
    },
    {
      "code": "PG",
      "name": "PAPUA NEW GUINEA",
      "futurenames": ""
    },
    {
      "code": "PY",
      "name": "PARAGUAY",
      "futurenames": ""
    },
    {
      "code": "PE",
      "name": "PERU",
      "futurenames": ""
    },
    {
      "code": "PH",
      "name": "PHILIPPINES",
      "futurenames": ""
    },
    {
      "code": "PN",
      "name": "PITCAIRN",
      "futurenames": ""
    },
    {
      "code": "PL",
      "name": "POLAND",
      "futurenames": ""
    },
    {
      "code": "PT",
      "name": "PORTUGAL",
      "futurenames": ""
    },
    {
      "code": "PR",
      "name": "PUERTO RICO",
      "futurenames": ""
    },
    {
      "code": "QA",
      "name": "QATAR",
      "futurenames": ""
    },
    {
      "code": "RE",
      "name": "RÉUNION",
      "futurenames": ""
    },
    {
      "code": "RO",
      "name": "ROMANIA",
      "futurenames": ""
    },
    {
      "code": "RW",
      "name": "RWANDA",
      "futurenames": ""
    },
    {
      "code": "BL",
      "name": "SAINT BARTHELEMY",
      "futurenames": ""
    },
    {
      "code": "SH",
      "name": "SAINT HELENA",
      "futurenames": ""
    },
    {
      "code": "KN",
      "name": "SAINT KITTS AND NEVIS",
      "futurenames": ""
    },
    {
      "code": "LC",
      "name": "SAINT LUCIA",
      "futurenames": ""
    },
    {
      "code": "MF",
      "name": "SAINT MARTIN",
      "futurenames": ""
    },
    {
      "code": "PM",
      "name": "SAINT PIERRE AND MIQUELON",
      "futurenames": ""
    },
    {
      "code": "VC",
      "name": "SAINT VINCENT AND THE GRENADINES",
      "futurenames": ""
    },
    {
      "code": "WS",
      "name": "SAMOA",
      "futurenames": ""
    },
    {
      "code": "SM",
      "name": "SAN MARINO",
      "futurenames": ""
    },
    {
      "code": "ST",
      "name": "SAO TOME AND PRINCIPE",
      "futurenames": ""
    },
    {
      "code": "SA",
      "name": "SAUDI ARABIA",
      "futurenames": ""
    },
    {
      "code": "SN",
      "name": "SENEGAL",
      "futurenames": ""
    },
    {
      "code": "RS",
      "name": "SERBIA",
      "futurenames": ""
    },
    {
      "code": "CS",
      "name": "SERBIA AND MONTENEGRO",
      "futurenames": ""
    },
    {
      "code": "SC",
      "name": "SEYCHELLES",
      "futurenames": ""
    },
    {
      "code": "SL",
      "name": "SIERRA LEONE",
      "futurenames": ""
    },
    {
      "code": "SG",
      "name": "SINGAPORE",
      "futurenames": ""
    },
    {
      "code": "SX",
      "name": "SINT MAARTEN",
      "futurenames": ""
    },
    {
      "code": "SK",
      "name": "SLOVAKIA",
      "futurenames": ""
    },
    {
      "code": "SI",
      "name": "SLOVENIA",
      "futurenames": ""
    },
    {
      "code": "SB",
      "name": "SOLOMON ISLANDS",
      "futurenames": ""
    },
    {
      "code": "SO",
      "name": "SOMALIA",
      "futurenames": ""
    },
    {
      "code": "ZA",
      "name": "SOUTH AFRICA",
      "futurenames": ""
    },
    {
      "code": "GS",
      "name": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
      "futurenames": ""
    },
    {
      "code": "SS",
      "name": "SOUTH SUDAN",
      "futurenames": ""
    },
    {
      "code": "ES",
      "name": "SPAIN",
      "futurenames": ""
    },
    {
      "code": "LK",
      "name": "SRI LANKA",
      "futurenames": ""
    },
    {
      "code": "SD",
      "name": "SUDAN",
      "futurenames": ""
    },
    {
      "code": "SR",
      "name": "SURINAME",
      "futurenames": ""
    },
    {
      "code": "SJ",
      "name": "SVALBARD AND JAN MAYEN",
      "futurenames": ""
    },
    {
      "code": "SZ",
      "name": "SWAZILAND",
      "futurenames": "ESWATINI"
    },
    {
      "code": "SE",
      "name": "SWEDEN",
      "futurenames": ""
    },
    {
      "code": "CH",
      "name": "SWITZERLAND",
      "futurenames": ""
    },
    {
      "code": "SY",
      "name": "SYRIAN ARAB REPUBLIC",
      "futurenames": ""
    },
    {
      "code": "TA",
      "name": "TAAF",
      "futurenames": ""
    },
    {
      "code": "TW",
      "name": "TAIWAN",
      "futurenames": ""
    },
    {
      "code": "TJ",
      "name": "TAJIKISTAN",
      "futurenames": ""
    },
    {
      "code": "TZ",
      "name": "TANZANIA, UNITED REPUBLIC OF",
      "futurenames": ""
    },
    {
      "code": "TH",
      "name": "THAILAND",
      "futurenames": ""
    },
    {
      "code": "TL",
      "name": "TIMOR-LESTE",
      "futurenames": ""
    },
    {
      "code": "TG",
      "name": "TOGO",
      "futurenames": ""
    },
    {
      "code": "TK",
      "name": "TOKELAU",
      "futurenames": ""
    },
    {
      "code": "TO",
      "name": "TONGA",
      "futurenames": ""
    },
    {
      "code": "TT",
      "name": "TRINIDAD AND TOBAGO",
      "futurenames": ""
    },
    {
      "code": "TN",
      "name": "TUNISIA",
      "futurenames": ""
    },
    {
      "code": "TR",
      "name": "TURKEY",
      "futurenames": ""
    },
    {
      "code": "TM",
      "name": "TURKMENISTAN",
      "futurenames": ""
    },
    {
      "code": "TC",
      "name": "TURKS AND CAICOS ISLANDS",
      "futurenames": ""
    },
    {
      "code": "TV",
      "name": "TUVALU",
      "futurenames": ""
    },
    {
      "code": "UG",
      "name": "UGANDA",
      "futurenames": ""
    },
    {
      "code": "UA",
      "name": "UKRAINE",
      "futurenames": ""
    },
    {
      "code": "AE",
      "name": "UNITED ARAB EMIRATES",
      "futurenames": ""
    },
    {
      "code": "UK",
      "name": "UNITED KINGDOM",
      "futurenames": ""
    },
    {
      "code": "UM",
      "name": "UNITED STATES MINOR OUTLYING ISLANDS",
      "futurenames": ""
    },
    {
      "code": "UY",
      "name": "URUGUAY",
      "futurenames": ""
    },
    {
      "code": "UZ",
      "name": "UZBEKISTAN",
      "futurenames": ""
    },
    {
      "code": "VU",
      "name": "VANUATU",
      "futurenames": ""
    },
    {
      "code": "VE",
      "name": "VENEZUELA",
      "futurenames": ""
    },
    {
      "code": "VN",
      "name": "VIET NAM",
      "futurenames": ""
    },
    {
      "code": "VG",
      "name": "VIRGIN ISLANDS, BRITISH",
      "futurenames": ""
    },
    {
      "code": "VI",
      "name": "VIRGIN ISLANDS, U.S.",
      "futurenames": ""
    },
    {
      "code": "WF",
      "name": "WALLIS AND FUTUNA",
      "futurenames": ""
    },
    {
      "code": "EH",
      "name": "WESTERN SAHARA",
      "futurenames": ""
    },
    {
      "code": "YE",
      "name": "YEMEN",
      "futurenames": ""
    },
    {
      "code": "ZM",
      "name": "ZAMBIA",
      "futurenames": ""
    },
    {
      "code": "ZW",
      "name": "ZIMBABWE",
      "futurenames": ""
    },
    {
      "code": "ZZ",
      "name": "ZZCOUNTRY",
      "futurenames": ""
    }
  ]
}

export function getStateSelectOptions() {
  return getStateInfo().map(state => {return {label: state.name, value: state.name}});
}

export function getStateInfo()
{
  return [
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
      "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
  ]
}


export function getTimezoneOptions() {
  return getTimezoneInfo().map(timezone => {return {label: timezone.text, value: timezone.value}});
}

export function getTimezoneInfo()
{
  return (
    [
      {"value":"Dateline Standard Time","abbr":"DST","offset":-12,"isdst":false,"text":"(UTC-12:00) International Date Line West","utc":"Etc/GMT+12"},
      {"value":"UTC-11","abbr":"U","offset":-11,"isdst":false,"text":"(UTC-11:00) Coordinated Universal Time-11","utc":"Etc/GMT+11"},
      {"value":"Hawaiian Standard Time","abbr":"HST","offset":-10,"isdst":false,"text":"(UTC-10:00) Hawaii","utc":"Etc/GMT+10"},
      {"value":"Alaskan Standard Time","abbr":"AKDT","offset":-8,"isdst":true,"text":"(UTC-09:00) Alaska","utc":"America/Anchorage"},
      {"value":"Pacific Standard Time","abbr":"PST","offset":-8,"isdst":false,"text":"(UTC-08:00) Pacific Time (US & Canada)","utc":"America/Los_Angeles"},
      {"value":"US Mountain Standard Time","abbr":"UMST","offset":-7,"isdst":false,"text":"(UTC-07:00) Arizona","utc":"America/Creston"},
      {"value":"Mountain Standard Time (Mexico)","abbr":"MDT","offset":-6,"isdst":true,"text":"(UTC-07:00) Chihuahua, La Paz, Mazatlan","utc":"America/Chihuahua"},
      {"value":"Mountain Standard Time","abbr":"MDT","offset":-6,"isdst":true,"text":"(UTC-07:00) Mountain Time (US & Canada)","utc":"America/Boise"},
      {"value":"Central America Standard Time","abbr":"CAST","offset":-6,"isdst":false,"text":"(UTC-06:00) Central America","utc":"America/Belize"},
      {"value":"Central Standard Time","abbr":"CDT","offset":-5,"isdst":true,"text":"(UTC-06:00) Central Time (US & Canada)","utc":"America/Chicago"},
      {"value":"Central Standard Time (Mexico)","abbr":"CDT","offset":-5,"isdst":true,"text":"(UTC-06:00) Guadalajara, Mexico City, Monterrey","utc":"America/Bahia_Banderas"},
      {"value":"Canada Central Standard Time","abbr":"CCST","offset":-6,"isdst":false,"text":"(UTC-06:00) Saskatchewan","utc":"America/Regina"},
      {"value":"SA Pacific Standard Time","abbr":"SPST","offset":-5,"isdst":false,"text":"(UTC-05:00) Bogota, Lima, Quito","utc":"America/Bogota"},
      {"value":"Eastern Standard Time","abbr":"EDT","offset":-4,"isdst":true,"text":"(UTC-05:00) Eastern Time (US & Canada)","utc":"America/Detroit"},
      {"value":"US Eastern Standard Time","abbr":"UEDT","offset":-4,"isdst":true,"text":"(UTC-05:00) Indiana (East)","utc":"America/Indiana/Marengo"},
      {"value":"Venezuela Standard Time","abbr":"VST","offset":-4.5,"isdst":false,"text":"(UTC-04:30) Caracas","utc":"America/Caracas"},
      {"value":"Paraguay Standard Time","abbr":"PYT","offset":-4,"isdst":false,"text":"(UTC-04:00) Asuncion","utc":"America/Asuncion"},
      {"value":"Atlantic Standard Time","abbr":"ADT","offset":-3,"isdst":true,"text":"(UTC-04:00) Atlantic Time (Canada)","utc":"America/Glace_Bay"},
      {"value":"Central Brazilian Standard Time","abbr":"CBST","offset":-4,"isdst":false,"text":"(UTC-04:00) Cuiaba","utc":"America/Campo_Grande"},
      {"value":"SA Western Standard Time","abbr":"SWST","offset":-4,"isdst":false,"text":"(UTC-04:00) Georgetown, La Paz, Manaus, San Juan","utc":"America/Anguilla"},
      {"value":"Pacific SA Standard Time","abbr":"PSST","offset":-4,"isdst":false,"text":"(UTC-04:00) Santiago","utc":"America/Santiago"},
      {"value":"Newfoundland Standard Time","abbr":"NDT","offset":-2.5,"isdst":true,"text":"(UTC-03:30) Newfoundland","utc":"America/St_Johns"},
      {"value":"E. South America Standard Time","abbr":"ESAST","offset":-3,"isdst":false,"text":"(UTC-03:00) Brasilia","utc":"America/Sao_Paulo"},
      {"value":"Argentina Standard Time","abbr":"AST","offset":-3,"isdst":false,"text":"(UTC-03:00) Buenos Aires","utc":"America/Argentina/La_Rioja"},
      {"value":"SA Eastern Standard Time","abbr":"SEST","offset":-3,"isdst":false,"text":"(UTC-03:00) Cayenne, Fortaleza","utc":"America/Araguaina"},
      {"value":"Greenland Standard Time","abbr":"GDT","offset":-3,"isdst":true,"text":"(UTC-03:00) Greenland","utc":"America/Godthab"},
      {"value":"Montevideo Standard Time","abbr":"MST","offset":-3,"isdst":false,"text":"(UTC-03:00) Montevideo","utc":"America/Montevideo"},
      {"value":"Bahia Standard Time","abbr":"BST","offset":-3,"isdst":false,"text":"(UTC-03:00) Salvador","utc":"America/Bahia"},
      {"value":"UTC-02","abbr":"U","offset":-2,"isdst":false,"text":"(UTC-02:00) Coordinated Universal Time-02","utc":"America/Noronha"},
      {"value":"Mid-Atlantic Standard Time","abbr":"MDT","offset":-1,"isdst":true,"text":"(UTC-02:00) Mid-Atlantic - Old", "utc":"test"},
      {"value":"Azores Standard Time","abbr":"ADT","offset":0,"isdst":true,"text":"(UTC-01:00) Azores","utc":"America/Scoresbysund"},
      {"value":"Cape Verde Standard Time","abbr":"CVST","offset":-1,"isdst":false,"text":"(UTC-01:00) Cape Verde Is.","utc":"Atlantic/Cape_Verde"},
      {"value":"Morocco Standard Time","abbr":"MDT","offset":1,"isdst":true,"text":"(UTC) Casablanca","utc":"Africa/Casablanca"},
      {"value":"UTC","abbr":"UTC","offset":0,"isdst":false,"text":"(UTC) Coordinated Universal Time","utc":"America/Danmarkshavn"},
      {"value":"GMT Standard Time","abbr":"GMT","offset":0,"isdst":false,"text":"(UTC) Edinburgh, London","utc":"Europe/Isle_of_Man"},
      {"value":"British Summer Time","abbr":"BST","offset":1,"isdst":true,"text":"(UTC+01:00) Edinburgh, London","utc":"Europe/Isle_of_Man"},
      {"value":"GMT Standard Time","abbr":"GDT","offset":1,"isdst":true,"text":"(UTC) Dublin, Lisbon","utc":"Atlantic/Canary"},
      {"value":"Greenwich Standard Time","abbr":"GST","offset":0,"isdst":false,"text":"(UTC) Monrovia, Reykjavik","utc":"Africa/Abidjan"},
      {"value":"W. Europe Standard Time","abbr":"WEDT","offset":2,"isdst":true,"text":"(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna","utc":"Arctic/Longyearbyen"},
      {"value":"Central Europe Standard Time","abbr":"CEDT","offset":2,"isdst":true,"text":"(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague","utc":"Europe/Belgrade"},
      {"value":"Romance Standard Time","abbr":"RDT","offset":2,"isdst":true,"text":"(UTC+01:00) Brussels, Copenhagen, Madrid, Paris","utc":"Africa/Ceuta"},
      {"value":"Central European Standard Time","abbr":"CEDT","offset":2,"isdst":true,"text":"(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb","utc":"Europe/Sarajevo"},
      {"value":"W. Central Africa Standard Time","abbr":"WCAST","offset":1,"isdst":false,"text":"(UTC+01:00) West Central Africa","utc":"Africa/Algiers"},
      {"value":"Namibia Standard Time","abbr":"NST","offset":1,"isdst":false,"text":"(UTC+01:00) Windhoek","utc":"Africa/Windhoek"},
      {"value":"GTB Standard Time","abbr":"GDT","offset":3,"isdst":true,"text":"(UTC+02:00) Athens, Bucharest","utc":"Asia/Nicosia"},
      {"value":"Middle East Standard Time","abbr":"MEDT","offset":3,"isdst":true,"text":"(UTC+02:00) Beirut","utc":"Asia/Beirut"},
      {"value":"Egypt Standard Time","abbr":"EST","offset":2,"isdst":false,"text":"(UTC+02:00) Cairo","utc":"Africa/Cairo"},
      {"value":"Syria Standard Time","abbr":"SDT","offset":3,"isdst":true,"text":"(UTC+02:00) Damascus","utc":"Asia/Damascus"},
      {"value":"E. Europe Standard Time","abbr":"EEDT","offset":3,"isdst":true,"text":"(UTC+02:00) E. Europe","utc":"Asia/Nicosia"},
      {"value":"South Africa Standard Time","abbr":"SAST","offset":2,"isdst":false,"text":"(UTC+02:00) Harare, Pretoria","utc":"Africa/Blantyre"},
      {"value":"FLE Standard Time","abbr":"FDT","offset":3,"isdst":true,"text":"(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius","utc":"Europe/Helsinki"},
      {"value":"Turkey Standard Time","abbr":"TDT","offset":3,"isdst":false,"text":"(UTC+03:00) Istanbul","utc":"Europe/Istanbul"},
      {"value":"Israel Standard Time","abbr":"JDT","offset":3,"isdst":true,"text":"(UTC+02:00) Jerusalem","utc":"Asia/Jerusalem"},
      {"value":"Libya Standard Time","abbr":"LST","offset":2,"isdst":false,"text":"(UTC+02:00) Tripoli","utc":"Africa/Tripoli"},
      {"value":"Jordan Standard Time","abbr":"JST","offset":3,"isdst":false,"text":"(UTC+03:00) Amman","utc":"Asia/Amman"},
      {"value":"Arabic Standard Time","abbr":"AST","offset":3,"isdst":false,"text":"(UTC+03:00) Baghdad","utc":"Asia/Baghdad"},
      {"value":"Kaliningrad Standard Time","abbr":"KST","offset":3,"isdst":false,"text":"(UTC+02:00) Kaliningrad","utc":"Europe/Kaliningrad"},
      {"value":"Arab Standard Time","abbr":"AST","offset":3,"isdst":false,"text":"(UTC+03:00) Kuwait, Riyadh","utc":"Asia/Aden"},
      {"value":"E. Africa Standard Time","abbr":"EAST","offset":3,"isdst":false,"text":"(UTC+03:00) Nairobi","utc":"Africa/Addis_Ababa"},
      {"value":"Moscow Standard Time","abbr":"MSK","offset":3,"isdst":false,"text":"(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk","utc":"Europe/Kirov"},
      {"value":"Samara Time","abbr":"SAMT","offset":4,"isdst":false,"text":"(UTC+04:00) Samara, Ulyanovsk, Saratov","utc":"Europe/Astrakhan"},
      {"value":"Iran Standard Time","abbr":"IDT","offset":4.5,"isdst":true,"text":"(UTC+03:30) Tehran","utc":"Asia/Tehran"},
      {"value":"Arabian Standard Time","abbr":"AST","offset":4,"isdst":false,"text":"(UTC+04:00) Abu Dhabi, Muscat","utc":"Asia/Dubai"},
      {"value":"Azerbaijan Standard Time","abbr":"ADT","offset":5,"isdst":true,"text":"(UTC+04:00) Baku","utc":"Asia/Baku"},
      {"value":"Mauritius Standard Time","abbr":"MST","offset":4,"isdst":false,"text":"(UTC+04:00) Port Louis","utc":"Indian/Mahe"},
      {"value":"Georgian Standard Time","abbr":"GET","offset":4,"isdst":false,"text":"(UTC+04:00) Tbilisi","utc":"Asia/Tbilisi"},
      {"value":"Caucasus Standard Time","abbr":"CST","offset":4,"isdst":false,"text":"(UTC+04:00) Yerevan","utc":"Asia/Yerevan"},
      {"value":"Afghanistan Standard Time","abbr":"AST","offset":4.5,"isdst":false,"text":"(UTC+04:30) Kabul","utc":"Asia/Kabul"},
      {"value":"West Asia Standard Time","abbr":"WAST","offset":5,"isdst":false,"text":"(UTC+05:00) Ashgabat, Tashkent","utc":"Antarctica/Mawson"},
      {"value":"Yekaterinburg Time","abbr":"YEKT","offset":5,"isdst":false,"text":"(UTC+05:00) Yekaterinburg","utc":"Asia/Yekaterinburg"},
      {"value":"Pakistan Standard Time","abbr":"PKT","offset":5,"isdst":false,"text":"(UTC+05:00) Islamabad, Karachi","utc":"Asia/Karachi"},
      {"value":"India Standard Time","abbr":"IST","offset":5.5,"isdst":false,"text":"(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi","utc":"Asia/Kolkata"},
      {"value":"Sri Lanka Standard Time","abbr":"SLST","offset":5.5,"isdst":false,"text":"(UTC+05:30) Sri Jayawardenepura","utc":"Asia/Colombo"},
      {"value":"Nepal Standard Time","abbr":"NST","offset":5.75,"isdst":false,"text":"(UTC+05:45) Kathmandu","utc":"Asia/Kathmandu"},
      {"value":"Central Asia Standard Time","abbr":"CAST","offset":6,"isdst":false,"text":"(UTC+06:00) Nur-Sultan (Astana)","utc":"Antarctica/Vostok"},
      {"value":"Bangladesh Standard Time","abbr":"BST","offset":6,"isdst":false,"text":"(UTC+06:00) Dhaka","utc":"Asia/Dhaka"},
      {"value":"Myanmar Standard Time","abbr":"MST","offset":6.5,"isdst":false,"text":"(UTC+06:30) Yangon (Rangoon)","utc":"Asia/Rangoon"},
      {"value":"SE Asia Standard Time","abbr":"SAST","offset":7,"isdst":false,"text":"(UTC+07:00) Bangkok, Hanoi, Jakarta","utc":"Antarctica/Davis"},
      {"value":"N. Central Asia Standard Time","abbr":"NCAST","offset":7,"isdst":false,"text":"(UTC+07:00) Novosibirsk","utc":"Asia/Novokuznetsk"},
      {"value":"China Standard Time","abbr":"CST","offset":8,"isdst":false,"text":"(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi","utc":"Asia/Hong_Kong"},
      {"value":"North Asia Standard Time","abbr":"NAST","offset":8,"isdst":false,"text":"(UTC+08:00) Krasnoyarsk","utc":"Asia/Krasnoyarsk"},
      {"value":"Singapore Standard Time","abbr":"MPST","offset":8,"isdst":false,"text":"(UTC+08:00) Kuala Lumpur, Singapore","utc":"Asia/Brunei"},
      {"value":"W. Australia Standard Time","abbr":"WAST","offset":8,"isdst":false,"text":"(UTC+08:00) Perth","utc":"Antarctica/Casey"},
      {"value":"Taipei Standard Time","abbr":"TST","offset":8,"isdst":false,"text":"(UTC+08:00) Taipei","utc":"Asia/Taipei"},
      {"value":"Ulaanbaatar Standard Time","abbr":"UST","offset":8,"isdst":false,"text":"(UTC+08:00) Ulaanbaatar","utc":"Asia/Choibalsan"},
      {"value":"North Asia East Standard Time","abbr":"NAEST","offset":8,"isdst":false,"text":"(UTC+08:00) Irkutsk","utc":"Asia/Irkutsk"},
      {"value":"Japan Standard Time","abbr":"JST","offset":9,"isdst":false,"text":"(UTC+09:00) Osaka, Sapporo, Tokyo","utc":"Asia/Dili"},
      {"value":"Korea Standard Time","abbr":"KST","offset":9,"isdst":false,"text":"(UTC+09:00) Seoul","utc":"Asia/Pyongyang"},
      {"value":"Cen. Australia Standard Time","abbr":"CAST","offset":9.5,"isdst":false,"text":"(UTC+09:30) Adelaide","utc":"Australia/Adelaide"},
      {"value":"AUS Central Standard Time","abbr":"ACST","offset":9.5,"isdst":false,"text":"(UTC+09:30) Darwin","utc":"Australia/Darwin"},
      {"value":"E. Australia Standard Time","abbr":"EAST","offset":10,"isdst":false,"text":"(UTC+10:00) Brisbane","utc":"Australia/Brisbane"},
      {"value":"AUS Eastern Standard Time","abbr":"AEST","offset":10,"isdst":false,"text":"(UTC+10:00) Canberra, Melbourne, Sydney","utc":"Australia/Melbourne"},
      {"value":"West Pacific Standard Time","abbr":"WPST","offset":10,"isdst":false,"text":"(UTC+10:00) Guam, Port Moresby","utc":"Antarctica/DumontDUrville"},
      {"value":"Tasmania Standard Time","abbr":"TST","offset":10,"isdst":false,"text":"(UTC+10:00) Hobart","utc":"Australia/Currie"},
      {"value":"Yakutsk Standard Time","abbr":"YST","offset":9,"isdst":false,"text":"(UTC+09:00) Yakutsk","utc":"Asia/Chita"},
      {"value":"Central Pacific Standard Time","abbr":"CPST","offset":11,"isdst":false,"text":"(UTC+11:00) Solomon Is., New Caledonia","utc":"Antarctica/Macquarie"},
      {"value":"Vladivostok Standard Time","abbr":"VST","offset":11,"isdst":false,"text":"(UTC+11:00) Vladivostok","utc":"Asia/Sakhalin"},
      {"value":"New Zealand Standard Time","abbr":"NZST","offset":12,"isdst":false,"text":"(UTC+12:00) Auckland, Wellington","utc":"Antarctica/McMurdo"},
      {"value":"UTC+12","abbr":"U","offset":12,"isdst":false,"text":"(UTC+12:00) Coordinated Universal Time+12","utc":"Etc/GMT-12"},
      {"value":"Fiji Standard Time","abbr":"FST","offset":12,"isdst":false,"text":"(UTC+12:00) Fiji","utc":"Pacific/Fiji"},
      {"value":"Magadan Standard Time","abbr":"MST","offset":12,"isdst":false,"text":"(UTC+12:00) Magadan","utc":"Asia/Anadyr"},
      {"value":"Kamchatka Standard Time","abbr":"KDT","offset":13,"isdst":true,"text":"(UTC+12:00) Petropavlovsk-Kamchatsky - Old","utc":"Asia/Kamchatka"},
      {"value":"Tonga Standard Time","abbr":"TST","offset":13,"isdst":false,"text":"(UTC+13:00) Nuku'alofa","utc":"Etc/GMT-13"},
      {"value":"Samoa Standard Time","abbr":"SST","offset":13,"isdst":false,"text":"(UTC+13:00) Samoa","utc":"Pacific/Apia"}
    ]
  )
}
