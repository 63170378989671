import React from "react";
import PropTypes from "prop-types";
import DefaultLayout from "@layout/DefaultLayout"
import {isMarketingManager, canViewEventReport, isEventNominationOpen,isEventCriteriaLocked,  isJaguar, isAccountManager, isExecutiveRelationsLead, canEditNomination,canEditEvents, canDeleteEvents, canBulkNominate} from "@utility/PermissionUtility"
import EventForm from "./forms/EventForm"
import UniversalImageCircle from "@images/UniversalImageCircle"
import  TabbedPageContainer from "@layout/tabs/TabbedPageContainer"
import { dateText } from "@utility/TimeUtils"
import GeneralTable from "@layout/tables/GeneralTable"
import  UngroupedNominationsTable from "@nominations/tables/UngroupedNominationsTable"
import EventCriteriaModal from "@events/modals/EventCriteriaModal"
import DropzoneComponent from "@images/DropzoneComponent"
import Cookies from "universal-cookie";
import EventNominationForm from "./forms/EventNominationForm"
import DefaultTooltip from "@layout/tooltips/DefaultTooltip";
import  LogTable from "@logs/LogTable"
import ReportEventTable from "@admin/reports/ReportEventTable"
import EventTokenForm from "@events/tokens/EventTokenForm"
import EventShowReports from "@events/reports/EventShowReports"
class EventsShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventIcon: props.event.icon ? props.event.icon : null,
      locked: isEventCriteriaLocked(props.event),
      token: props.token,
    };
    this.setIcon = this.setIcon.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.archiveEvent = this.archiveEvent.bind(this);
    this.renderLogs = this.renderLogs.bind(this);
    this.unarchiveEvent = this.unarchiveEvent.bind(this);
    this.updateToken = this.updateToken.bind(this);
  }

  updateToken(token) {
    this.setState({
      token
    })
  }

  setIcon(name, file, preview, tagList) {
    const { event } = this.props;
    var form_data = new FormData();
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    form_data.append('event[icon]', file);

    this.setState({
      eventIcon: preview
    });

    fetch(`/events/${event.id}/update_icon`, {
      method: 'POST',
      redirect: "manual",
      body: form_data,
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {

      } else {
        console.log(json.error);
      }
    });
  }

  renderHeader() {
    const { event, user } = this.props;
    const { eventIcon, token} = this.state;
    return (
      <div className="row cnt-event-show-header-container">
        <div className="cnt-event-show-header-container-image col-xs-12 col-sm-4 col-lg-3">
          <UniversalImageCircle size={'large'} image={eventIcon} name={event.name} id={event.id}/>
        </div>
        <div className="cnt-event-show-header-container-description col-xs-12 col-sm-8 col-lg-10">
          <div className="cnt-event-show-header-container-name">
            {event.name}
            {this.renderNominateButton()}
          </div>
          <div className="cnt-event-show-header-container-date">
            {canEditEvents(user) ? (
              <>
              <DropzoneComponent outerStyle={{display:"inline-block"}} setFiles={this.setIcon}>
                <a href="#">Upload Photo</a>
              </DropzoneComponent>
              {event.archived_at == null ? (
                <a href="#" onClick={this.archiveEvent} style={{display:'inline-block', marginLeft:"20px"}}> Archive Event</a>
              ):(<a href="#" onClick={this.unarchiveEvent} style={{display:'inline-block', marginLeft:"20px"}}> Unarchive Event</a>)}
              {canDeleteEvents(user) ?
                (
                  <a href="#" onClick={this.deleteEvent} style={{display:'inline-block', marginLeft:"20px", color: "red"}}> Delete Event</a>
                ):""
              }

              </>
            ):""}
          </div>
        </div>
      </div>
    )
  }


  archiveEvent() {
    console.log("Archiving Event");
    if(!confirm("Are you sure you want to archive this event?")){
      return;
    }
    const { event } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/events/${event.id}/archive`, {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify({}),
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        window.location = json.redirect_url
      } else {
        console.log(json.error);
      }
    });
  }

  unarchiveEvent() {
    console.log("Archiving Event");
    if(!confirm("Are you sure you want to unarchive this event?")){
      return;
    }
    const { event } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/events/${event.id}/unarchive`, {
      method: 'PATCH',
      redirect: "manual",
      body: JSON.stringify({}),
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        window.location = json.redirect_url
      } else {
        console.log(json.error);
      }
    });
  }

  deleteEvent() {
    if(!confirm("Are you sure you want to delete this event? This will delete all associated nominations and cannot be undone")){
      return;
    }
    const { event } = this.props;
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");
    fetch(`/events/${event.id}`, {
      method: 'DELETE',
      redirect: "manual",
      body: JSON.stringify({}),
      headers: {
        "X-CSRF-Token": token,
      }
    })
    .then(response => {
      return response.json();
    })
    .then(json => {
      if (json.error == null) {
        window.location = json.redirect_url
      } else {
        console.log(json.error);
      }
    });
  }

  renderNominateButton() {
    const { user, event } = this.props;
      return (
        <div style={{position: "absolute", right: "0px", top: "20px", zIndex:"3", display: "flex"}}>
          {canEditNomination(user) && isEventNominationOpen(event) ? (
            <div>
              {this.isNominationButtonDisabled() ? (
                <DefaultTooltip
                  tooltipId={`sg-cnt-event-nominate-tooltip`}
                >
                  Not Live
                </DefaultTooltip>
              ):""}
              <a
                href={this.isNominationButtonDisabled() ? "#" : `/nominations/new?event_id=${event.id}`}
              >
                <button
                  disabled={this.isNominationButtonDisabled()}
                  className="cnt-page-header-button"
                >
                  <div
                    data-for={`sg-cnt-event-nominate-tooltip`}
                    data-tip
                  >
                    New Nomination
                  </div>
                </button>
              </a>
            </div>
          ):""}

          <div style={{fontSize: "14px", marginLeft: "1em"}}>
            {canBulkNominate(user) ? (
              <>
              <a
                href={`/imports/index?event_id=${event.id}`}
              >
                <button
                  className="cnt-page-header-button"
                  style={{backgroundColor: "#448A2D", marginLeft: "0px"}}
                >
                  <div
                    data-for={`sg-cnt-event-import-tooltip`}
                    data-tip
                  >
                    Nomination Batch Upload
                  </div>
                </button>
              </a>
              <br/>
              <div style={{width: "100%", textAlign: "center", marginTop: "25px"}}>
                <a href={`/imports/download_example_sheet?event_id=${event.id}`} style={{fontSize: "14px"}}>Download AM Upload Template</a>
                <br/>
                or
                <br/>
                <a href={`/imports/download_example_sheet?event_id=${event.id}&complete=true`} style={{fontSize: "14px"}}>Download Verified Upload Template</a>
              </div>
              </>
            ):""}


          </div>
        </div>
      )
  }


  isNominationButtonDisabled() {
    const { event } = this.props;
    return event && event.award_criteria && event.award_criteria.locked ? false : true;
  }


  renderNomineesTab() {
    return (
      <>
        <NominationsTable
          {...this.props}
        />
      </>
    )
  }

  getTabs() {
    const { user, nominations, event } = this.props;
    const { token } = this.state;
    const { locked } = this.state;
    console.log({event})
    let allTabs = [
      {id: "details", name: "Event Info", content:(
        <>
          <EventForm {...this.props} />
          <EventTokenForm onChangeToken={this.updateToken} token={token} event={event} user={user}/>
        </>
      )},
      {id: "nomform", name: (<>{locked ? (<img style={{height: "1em",marginRight:"5px",marginBottom: "6px"}} src="/layout/locked.svg"/> ):""}Nom Questions</>), content:(
        <EventNominationForm {...this.props} />
      )},
      {id: "nominations", name: "Nominations", content: <><br/><UngroupedNominationsTable show_submitter={event.options.show_submitter} show_source={event.options.show_source}  show_vip={event.show_vip} show_opt_out={event.options.show_opt_out} show_segment_p24={event.options.show_segment_p24} show_priority={event.options.show_priority} show_drafts={true} includeSuperFilter={true} filteredColumns={['event_name']} event={event} nominations={nominations} user={user}/></>},
      {id: "reports", name: "Reports", content: <EventShowReports token={token} event={event} user={user}/>},
      {id: "log", name: "Log", content: this.renderLogs}
    ];

    if(isAccountManager(user)) {
      allTabs = allTabs.filter(x=> x.id != 'log')
      allTabs = allTabs.filter(x=> x.id != 'nomform')
      allTabs = allTabs.filter(x=> x.id != 'reports')
    }
    return allTabs;
  }

  renderLogs() {
    const { event } = this.props;
    return (
      <LogTable
        logType="events"
        logObject={event}
       />
    )
  }

  render() {
    const { user, event } = this.props;
    const { locked } = this.state;
    return (
      <DefaultLayout
        {...this.props}
        breadcrumbs={
          ['dashboard', 'events']
        }
        sidebarId="events"
      >
        {this.renderHeader()}
        <TabbedPageContainer
          pages={this.getTabs()}
          default_tab_id={locked ? ("nominations"): "details"}
        />
      </DefaultLayout>
    );
  }
}

export default EventsShow;
