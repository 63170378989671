import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import Select from "react-select";

class SelectField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      option: props.defaultValue === null ? props.defaultValue : {}
    };
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  componentDidMount() {
    const { headerValues, index, options } = this.props;
    let fieldMatch = '';
    options.forEach(model => {
      model.options.forEach(option => {
        if (headerValues[index].toLowerCase().trim().includes(option.label.toLowerCase().trim())) {
          if (headerValues[index].toLowerCase().trim().includes(model.label.toLowerCase().trim())) {
            fieldMatch = option;
          }
        }

        // User -> Account Manager special case
        if (headerValues[index].toLowerCase().trim().includes("user") && model.label === "Account Manager") {
          if (headerValues[index].toLowerCase().trim().includes(option.label.toLowerCase().trim())) {
            fieldMatch = option;
          }
        }
      });
    });
    if (fieldMatch !== '') {
      this.onSelectChange(fieldMatch);
    }
  }

  customStyles() {
    const { customStyle } = this.props;
    if (customStyle) {
      return customStyle;
    }
    return ({
      indicatorsContainer: () => ({
        display: 'none'
      }),
      menu: () => ({
        position: 'absolute',
        top: 'calc(100% + 10px)',
        width: '20vw',
        backgroundColor: 'white',
        border: '1px solid hsl(0,0%,80%)',
        borderRadius: '5px',
        zIndex: '10'
      }),
      control: () => ({
        minHeight: "42px",
        alignItems: "center",
        backgroundColor: "hsl(0,0%,100%)",
        borderColor: "hsl(0,0%,80%)",
        borderRadius: "4px",
        borderStyle: "solid",
        borderWidth: "1px",
        cursor: "default",
        display: "-webkit-box",
        display: "-webkit-flex",
        display: "-ms-flexbox",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        outline: "0 !important",
        position: "relative",
        transition: "all 100ms",
        boxSizing: "border-box"
      })
    })
  }

  onSelectChange(e) {
    const { onChange } = this.props;
    onChange(e);
    this.setState({option: e});
  }

  render() {
    const { fieldName, onChange, options } = this.props;
    const { option } = this.state;
    if (onChange) {
      return (
        <Select
          name={fieldName}
          value={option}
          options={options}
          styles={this.customStyles()}
          onChange={(e) => this.onSelectChange(e)}
          grouped
        />
      )
    }
    return (
      <Select
        name={fieldName}
        value={option}
        options={options}
        styles={this.customStyles()}
        grouped
      />
    );
  }
}

SelectField.defaultProps = {
  blankLabel: "Select option",
  includeBlank: false,
  onChange: null
};

SelectField.propTypes = {
  blankLabel: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  includeBlank: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default SelectField;
